<template>
  <div class="sign-area">
    <div class="title-wrap">
      <h2
        :class="{ kor: $i18n.locale === 'ko' }"
      > {{ $t('content.auth.SignIn.Title') }}
      </h2>
      <div class="title-desc">
        {{ $t('content.auth.SignIn.TitleDesc') }}
      </div>
    </div>

    <div class="sign-container">
      <StaggerTransition>
        <ul class="sns-sign ani-stagger">
          <li>
            <NaverLogin
              type="signIn"
            />
          </li>
          <!-- <li>
            <FacebookLogin
              type="signIn"
              @authenticated="onAuthenticated"
            />
          </li> -->
          <li>
            <GoogleLogin
              type="signIn"
              @authenticated="onAuthenticated"
            />
          </li>
          <li>
            <KakaoLogin
              type="signIn"
              @authenticated="onAuthenticated"
            />
          </li>
        </ul>
      </StaggerTransition>
    </div>
  </div>
</template>

<script>
import SignIn from '@/views/auth/SignIn.vue';

export default {
  name: 'MobileSignIn',
  extends: SignIn,
};
</script>

<style scoped>
.sign-area .sns-sign .sns-item { display: block; height: 5.6rem; line-height: 5.6rem; text-align: center; color: #fff; font-size: 1.6rem; font-weight: 700; 
background-image: url("../../assets/m_images/icons_login_2.png") !important; background-repeat: no-repeat; background-size: 5.6rem auto; }
</style>